<template>
  <div class="landlords">
    <landlordsHero />
    <landlordsRoom />
    <landlordsQuestionnaire id="landlords-form" />
    <landlordsConnection />
    <partnersReviews />
  </div>
</template>

<script>
export default {
  name: "Landlords",
  components: {
    LandlordsHero: () =>
      import("@/components/pages/landlords/LandlordsHero.vue"),
    LandlordsRoom: () =>
      import("@/components/pages/landlords/LandlordsRoom.vue"),
    LandlordsQuestionnaire: () =>
      import("@/components/pages/landlords/LandlordsQuestionnaire.vue"),
    LandlordsConnection: () =>
      import("@/components/pages/landlords/LandlordsConnection.vue"),
    PartnersReviews: () => import("@/components/common/PartnersReviews.vue"),
  },
};
</script>
